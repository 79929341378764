import {ChangeDetectorRef, Component, HostListener, Input, OnInit} from '@angular/core';
import {AddTaskModalComponent} from "@app-web-central/web/task/ui/add-modal";
import {NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {
  CfUser,
  NavItem,
  NavItemMixin,
  CfOrganization,
  ThemesForSelect,
  UserStatusValuesDisplay,
} from "@app-web-central/web/shared/data-access/models";
import {ModalUserStatusComponent} from "@app-web-central/web/shell/ui/modal-user-status";
import {DrawerHelpComponent} from "@app-web-central/web/shared/components/drawers/drawer-help";
import {OrganizationsFacade} from "@app-web-central/web/organization/data-access";
import {RouteUtil} from "@app-web-central/web/shared/utils";
import {UsersFacade, UserStore} from "@app-web-central/web/user/data-access";
import {BreadcrumbsStore, HeaderLinkStore} from "@app-web-central/web/shared/data-access/store";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Observable, take} from "rxjs";
import {NotificationsFacade} from "@app-web-central/web/notification/data-access";
import {ModalActionInformationComponent} from "@app-web-central/web/shell/ui/modal-action-information";
import {DrawerSearchComponent} from "@app-web-central/web/shared/components/drawers/drawer-search";
import {AuthFacade} from "@app-web-central/web/auth/data-access";
import {FormControl, Validators} from "@angular/forms";
import {ThemeService} from "@app-web-central/web/shared/services/theme";
import {Router} from "@angular/router";


@UntilDestroy()
@Component({
  selector: 'as-nav-bar-desktop-menu',
  templateUrl: './nav-bar-desktop-menu.component.html',
  styleUrls: ['./nav-bar-desktop-menu.component.scss']
})
export class NavBarDesktopMenuComponent implements OnInit {
  @Input() hasCreationButton = true;
  @Input() hasBreadcrumbs = false;
  public session$: Observable<CfUser | null>
    = this.authFacade.user$;
  public organization$: Observable<CfOrganization>
    = this._organizationFacade.organization$;
  public themesForSelect = ThemesForSelect;
  public themeControl: FormControl
    = new FormControl(null, Validators.required);

  quickItems!: NavItem[];
  menuLinks!: NavItemMixin[];
  modifierKey = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) ? '⌘' : 'Ctrl ';

  constructor(
    private router: Router,
    private userStore: UserStore,
    private _modal: NzModalService,
    private _cdRef: ChangeDetectorRef,
    private themeService: ThemeService,
    private _drawerService: NzDrawerService,
    public headerLinkStore: HeaderLinkStore,
    public breadcrumbStore: BreadcrumbsStore,
    private _usersFacade: UsersFacade,
    private authFacade: AuthFacade,
    public notificationsFacade: NotificationsFacade,
    private _organizationFacade: OrganizationsFacade,
  ) { }

  ngOnInit(): void {
    this.handleCreateQuickItems();
    this.themeControl.setValue(this.themeService.getUserPreference ? this.themeService.getUserPreference.toUpperCase() : 'SYSTEM');
    this.headerLinkStore.headerLinkItems$
      .pipe(take(1), untilDestroyed(this))
      .subscribe((header: NavItemMixin[]) => {
        if (header) {
          this.menuLinks = header;
        }
      });
  }

  private handleCreateQuickItems(): void {
    this.quickItems = [
      new NavItem('tooltips.search', 'outline/magnifying-glass', this.handleOpenDrawerSearch.bind(this)),
      new NavItem('tooltips.help', 'outline/question-circle', this.handleOpenHelpDrawer.bind(this)),
      new NavItem('tooltips.notifications', 'outline/bell'),
    ];
  }

  getCurrentStatus(session: CfUser) {
    return UserStatusValuesDisplay.find((x) => x.status === session.accountStatus);
  }

  setUserSessionAsAway(user: CfUser) {
    this._usersFacade.setAsAway(user);
  }

  checkQuickItem(NavItems: NavItem[], clickedNavItem: NavItem) {
    this.quickItems.forEach((item) => {
      item.checked = false;
      item.label === clickedNavItem.label ? item.checked = true : item.checked = false
    });
  }

  onResetCheckedQuickItems(isVisible: boolean) {
    if (!isVisible) {
      this.quickItems.forEach((item) => item.checked = false);
      this._cdRef.detectChanges();
    }
  }

  @HostListener('window:keydown', ['$event'])
  public keyEvent(event: KeyboardEvent): void {
    if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
      event.preventDefault()
      this.handleOpenDrawerSearch();
    }
  }

  public handleOpenDrawerSearch(): void {
    const drawer: NzDrawerRef<DrawerSearchComponent> = this._drawerService.create({
      nzContent: DrawerSearchComponent,
      nzPlacement: 'left',
      nzClosable: false,
      nzWidth: 500
    });

    drawer.afterClose
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.onResetCheckedQuickItems(false);
      });
  }

  public handleOpenHelpDrawer(): void {
    const nzDrawer: NzDrawerRef<DrawerHelpComponent> = this._drawerService.create({
      nzContent: DrawerHelpComponent,
      nzPlacement: 'right',
      nzClosable: false,
      nzWidth: 300
    });

    nzDrawer.afterClose
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.onResetCheckedQuickItems(false);
      });
  }

  public handleOpenAddEvent(): void {
    this._modal.create({
      nzContent: AddTaskModalComponent,
      nzClosable: false,
      nzWidth: 1100,
      nzFooter: null,
      nzStyle: {top: '20px'}
    });
  }

  public handleGoToProfile(): void {
    this.router.navigateByUrl(RouteUtil.getMyProfileRouteUrl());
  }

  public handleOpenUserStatus(session: CfUser): void {
    this._modal.create({
      nzContent: ModalUserStatusComponent,
      nzComponentParams: { session },
      nzWidth: 500,
      nzClosable: false,
      nzFooter: null
    });
  }

  public handleLogOut(user: CfUser): void {
    const modal: NzModalRef<ModalActionInformationComponent> = this._modal.create({
      nzContent: ModalActionInformationComponent,
      nzComponentParams: {
        title: user.fullName,
        content: 'modals.logout.title'
      },
      nzClosable: false,
      nzWidth: 450,
      nzFooter: null
    });

    modal.afterClose
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        if (result) {
          this.authFacade.logout(user);
        }
      });
  }

  public handleChangeTheme(user: CfUser): void {
    this.themeService.toggleTheme(this.themeControl.value.toLowerCase());
    if (this.themeControl.invalid) {
      return;
    }
    const newUser: CfUser = { ...user };
    const newUserSettings = { ...newUser.settings };
    newUserSettings.userAppTheme = this.themeControl.value;
    newUser.settings = { ...newUserSettings };
    this.userStore.updateMe(newUser);
  }

}
