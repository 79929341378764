import {
  addDays,
  subDays,
  startOfDay,
  getDay,
  getMonth,
  getYear,
  getDate,
  startOfMonth,
  endOfMonth,
  endOfDay,
  startOfYear,
  endOfYear,
  format,
  isToday,
  addMinutes,
  areIntervalsOverlapping,
  differenceInMilliseconds,
  differenceInMinutes,
  startOfWeek,
  isSameWeek,
  eachMonthOfInterval
} from 'date-fns';

export class DateUtils {
  static differenceBetweenInMillis(leftDate: number, rightDate: number) {
    return differenceInMilliseconds(rightDate, leftDate);
  }

  static differenceBetweenInMinutes(leftDate: number, rightDate: number) {
    return differenceInMinutes(rightDate, leftDate);
  }

  static startOfYear(date: number | Date): Date {
    return startOfYear(date);
  }

  static endOfYear(date: number | Date): Date {
    return endOfYear(date);
  }

  static setNewDateWithHours(date: Date, hours: number, min: number) {
    const newDate = new Date(date);
    return newDate.setHours(hours, min, min);
  }

  static addDay(date: Date): Date {
    return addDays(date, 1);
  }

  static addMinutes(date: Date, minutes: number): Date {
    return addMinutes(date, minutes);
  }

  static subDay(date: Date): Date {
    return subDays(date, 1);
  }

  static isDateWithinDay(taskDate: number, date: Date) {
    return taskDate > startOfDay(date).getTime()
      && taskDate <= endOfDay(date).getTime();
  }

  static isDateWithinWeek(taskDate: number, date: Date) {
    const startOfWk = startOfWeek(date, { weekStartsOn: 1});
    return isSameWeek(startOfWk, taskDate, { weekStartsOn: 1 });
  }

  static isDateWithinNext7Days(taskDate: number, date: Date) {
    return taskDate > startOfDay(date).getTime()
      && taskDate <= endOfDay(addDays(date, 7)).getTime();
  }

  static isDateWithinMonth(leftDate: number) {
    const currentDate = new Date();
    return leftDate > startOfMonth(currentDate).getTime()
      && leftDate <= endOfMonth(currentDate).getTime();
  }

  static getDay(date: Date | number) {
    return getDay(date);
  }

  static getMonth(date: Date | number) {
    return getMonth(date);
  }

  static getDayInMonth(date: Date | number) {
    return getDate(date);
  }

  static getYear(date: Date | number) {
    return getYear(date);
  }

  static getTimeNow() {
    return format(new Date(), 'HH:mm');
  }

  static isDatesIntervalsOverlapping(intervalLeft: Interval, intervalRight: Interval) {
    return areIntervalsOverlapping(intervalLeft, intervalRight);
  }

  static isDateToday(date: Date | number) {
    return isToday(date);
  }

  static getStartOfDay(date: Date | number) {
    return startOfDay(date);
  }

  static getEndOfDay(date: Date | number) {
    return endOfDay(date)
  }

  static getEachMonthOfInterval(startDate: number): Date[] {
    return eachMonthOfInterval({ start: startDate, end: new Date().getTime() });
  }

  static today() {
    return new Date().getTime();
  }

  static startOfDay(date: Date | number): Date {
    return startOfDay(date);
  }

  static endOfDay(date: Date | number): Date {
    return endOfDay(date);
  }
}
