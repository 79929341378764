import * as XLSX from "xlsx";

export class ExportUtils {
  public static exportData(objects: any | any[], sheetName: string): void {
    const entities: any[] = Array.isArray(objects) ? objects : [objects];
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(entities);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${sheetName}-${new Date().getTime()}.xlsx`);
  }

}
