import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {NotificationsState} from "./notifications.reducer";
import {
  createReminder,
  loadMore10Notifications,
  loadNotifications, readAllNotification,
  readOneNotification,
  updateReminder
} from "./notifications.actions";
import {CfReminder, CfSession, CfUserNotification} from "@app-web-central/web/shared/data-access/models";
import {CookieService} from "ngx-cookie-service";
import {getNotifications, getNotificationsLoading} from "./notifications.selectors";
import {map, Observable} from "rxjs";

const OcfCookie = 'ocf-session';

@Injectable({ providedIn: 'root' })
export class NotificationsFacade {
  public isLoading$: Observable<boolean>
    = this.store.pipe(select(getNotificationsLoading));
  public notifications$: Observable<CfUserNotification[]>
    = this.store.pipe(select(getNotifications));
  public hasUnreadNotifications$: Observable<boolean> = this.notifications$.pipe(
    map((notifications: CfUserNotification[]) => notifications
      .some((notification: CfUserNotification) => !notification.markedAsRead)
    )
  );

  constructor(
    private store: Store<NotificationsState>,
    private cookieService: CookieService
  ) { }

  /**
   * Initialize on app startup the last 10 notifications.
   *
   */
  public loadNotifications(): void {
    this.store.dispatch(loadNotifications());
  }

  /**
   * Load more 10 notifications and add to previous state.
   *
   * @param userId the user id as { string }.
   */
  public loadMore10Notifications(userId: string): void {
    this.store.dispatch(loadMore10Notifications({ userId }));
  }

  /**
   * Read one notification by id.
   *
   * @param notificationId as { string }.
   */
  public readOneNotification(notificationId: string): void {
    this.store.dispatch(readOneNotification({ notificationId }));
  }

  /**
   * Read all notifications for user id.
   *
   */
  public readAllNotifications(): void {
    const userId = this.getCurrentSession().id;
    if (userId) {
      this.store.dispatch(readAllNotification({ userId }));
    }
  }

  /**
   * Create a reminder object.
   *
   * @param reminder as { CfReminder }.
   * @param reminderType as { string }.
   */
  public createReminder(reminder: CfReminder, reminderType: string): void {
    this.store.dispatch(createReminder({ reminder, reminderType }));
  }

  /**
   * Update a reminder object.
   *
   * @param reminder as { CfReminder }.
   * @param reminderType as { string }.
   */
  public updateReminder(reminder: CfReminder, reminderType: string): void {
    this.store.dispatch(updateReminder({ reminderId: reminder.id, reminder, reminderType }));
  }

  /**
   * Get item from cookie storage by key.
   *
   * @return {CfSession}.
   */
  private getCurrentSession(): CfSession {
    const value = this.cookieService.get(OcfCookie);
    try {
      return JSON.parse(value);
    } catch (e) {
      return {} as CfSession;
    }
  }

}
